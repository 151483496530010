<template>
    <!-- Site Back Top -->
    <div class="site-backtop" title="Back to top">
        <i class="fa fa-angle-up"></i>
    </div>
    <!-- Site Back Top End -->

    <div class="header">
        <div class="site-header">
            <!-- Header Top -->
            <div class="header-top">
                <div class="wrapper">
                    <div class="header-contact">
                        <ul>
                            <li><a :href="globals.phoneNumberLink"><i class="fa fa-phone"></i> &nbsp; {{globals.phoneNumber}}</a></li>

                        </ul>
                    </div>
                    <div class="header-lang">
                        <ul>
                            <li><a :href="globals.facebookUrl"
                                    target="_blank"><i class="fa fa-facebook"></i></a></li>
                            <li><a :href="globals.instagramUrl" target="_blank"><i
                                        class="fa fa-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- Header Top End -->
            <!-- Header Bottom -->
            <div class="header-bottom">
                <div class="wrapper">
                    <div class="header-logo" >
                        <a href="/"><img :src="globals.logo" alt="Fluegel Alumni Center"></a>
                    </div>
                    <div class="header-nav">
                        <ul class="nav-left">
                            <li>
                                <a href="/" :class="{ 'active': $route.path === '/' }">HOME</a>
                            </li>
                            <li>
                                <a href="/about" :class="{ 'active': $route.path === '/about' }">ABOUT</a>
                            </li>
                            <li>
                                <a href="/spaces" :class="{ 'active': $route.path === '/spaces' }">SPACES</a>
                            </li>
                        </ul>
                        <ul class="nav-right">
                            
                            <li>
                                <a href="/services" :class="{ 'active': $route.path === '/services' }">EVENTS</a>
                            </li>
                            <li>
                                <a href="/gallery" :class="{ 'active': $route.path === '/gallery' }">GALLERY</a>
                            </li>
                            <li>
                                <a href="/contact" :class="{ 'active': $route.path === '/contact' }">CONTACT</a>
                            </li>
                            
                        </ul>
                    </div>
                    <div class="header-toggle">
                        <i class="fa fa-bars"></i>
                    </div>
                </div>
            </div>
            <!-- Header Bottom End -->
        </div>
    </div>
</template>

<script>
import globals from '@/data/globals.json'
export default {
    name: 'Header',
   
  methods: { handleClick() { this.appInsights.trackEvent({ name: 'buttonClicked' }) } },
    data() {
        return {
            globals: globals
        }
    },
    mounted(){

    },
    props: {

    }
}
</script>