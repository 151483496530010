<template>
    <!-- Section Rooms -->
    <div class="section">
      <div class="widget-rooms-carousel top-over">
        <div class="wrapper-inner">
          <!-- Rooms Carousel -->
          <div class="widget-carousel owl-carousel owl-theme">
            <div v-for="(space, index) in spaces" class="rooms-item">
              <div class="item-inner">
                <div class="item-photo">
                  <a
                    href="/spaces"
                    :style="`background-image: url(${space.image});`"
                  ></a>
                </div>
                <div class="item-desc">
                  <h2>
                    <a href="/spaces">{{ space.title }}</a>
                  </h2>
                  <h3>{{ space.subtitle }}</h3>
                  <p>
                    {{space.description}}
                  </p>
                 
                </div>
              </div>
            </div>
          </div>
          <!-- Rooms Carousel End -->
        </div>
      </div>
    </div>
    <!-- Section Rooms End -->
  </template>
  
  <script>
  import spaces from "@/data/spaces.json";
  export default {
    name: "Rooms", // Your component's name
    components: {}, // Any child components used in this component
    props: {}, // Properties passed to this component
    data() {
      return {
        spaces: spaces
      };
    },
    computed: {
      // Your computed properties go here
    },
    methods: {
      // Your component's methods go here
    },
    mounted() {
      /*-- WIDGET ROOMS CAROUSEL --*/
      if ($(".widget-rooms-carousel").length) {
        $(".widget-rooms-carousel .widget-carousel").owlCarousel({
          responsive: {
            0: { items: 1 },
            991: { items: 2 },
            1200: { items: 3 },
          },
        });
      }
      /*-- WIDGET ROOMS CAROUSEL END --*/
    },
  };
  </script>
  
  <style>
  /* Your component's styles go here */
  </style>
  