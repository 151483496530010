<template>
  <!-- Site Main -->
  <div class="site-main">
    <!-- Section Slider -->
    <div class="section">
      <div class="widget-slider">
        <div class="wrapper-full">
          <!-- Slider Carousel -->
          <div class="widget-carousel owl-carousel owl-theme">
            <div class="slider-item" style="
                background-image: url('/assets/images/building/slider8.jpg');
              ">
              <div class="wrapper">
                <div class="item-inner">

                  <h5>HOST YOUR NEXT EVENT</h5>
                  <h1>Next to the Naval Academy</h1>
                  <h2>Located in Downtown Annapolis</h2>
                </div>
              </div>
            </div>
            <div class="slider-item" style="
            
                background-image: url('/assets/images/building/slider9.png');
              ">
              <div class="wrapper">
                <div class="item-inner">
                  <h5>EXPERIENCE THE ELEGANCE &amp; HISTORY</h5>
                  <h1>at Fluegel Alumni Center</h1>
                  <h2>
                    by U.S. Naval Academy Alumni Association & Foundation
                  </h2>
                </div>
              </div>
            </div>
            <div class="slider-item" style="background-image:url('/assets/images/building/slider3.jpg')">
              <div class="wrapper">
                <div class="item-inner">
                  <h5>BOOK FLUEGEL FOR YOUR NEXT OCCASION</h5>
                  <h1>Your Ideal Event Venue</h1>
                  <h2>In the Heart of Annapolis</h2>
                </div>
              </div>
            </div>
          </div>
          <!-- Slider Carousel End -->
          <!-- Slider Booking -->
          <div class="slider-booking" style="bottom:200px; display:block !important;">
            <div class="wrapper">


              <ul
                style="width: 300px; display: flex; justify-content: space-between; padding: 0; list-style-type: none;">
                <li style="flex: 1; text-align: center;">
                  <a href="/spaces" class="btn" style="display: inline-block; width: 100%;">Spaces</a>
                </li>
                <li style="flex: 1; text-align: center;">
                  <a href="/tour/index.htm" target="_blank" class="btn"
                    style="display: inline-block; width: 100%;">Tour</a>
                </li>
              </ul>


            </div>
          </div>
          <!-- Slider Booking End -->
        </div>
      </div>
    </div>
    <!-- Section Slider End -->

    <Rooms></Rooms>

    <!-- Section About Promo -->
    <div class="section">
      <div class="widget-about-promo" style="background-image: url('/assets/images/stock/photo-about.jpg')">
        <div class="wrapper-inner">
          <div class="widget-inner">
            <div class="row">
              <div class="col-md-6">
                <h5>ABOUT THE AREA</h5>
                <h2>Minutes From Downtown</h2>
                <p>
                  The Fluegel Alumni Center is conveniently located on King George Street in Annapolis offers free
                  on-site parking for up to 65 vehicles, depending on the size of your event. The easy-to-access
                  location is just minutes off Route 50 and about a half-mile from Navy Marine Corps Memorial Stadium.
                  The heart of historic Downtown Annapolis is only a few minutes’ walk down the road. The King George
                  Street location provides easy access to USNA Gate 1 and 8 for guests who may want to visit the Academy
                  Grounds.
                </p>
                <a href="/about" class="btn">LEARN MORE</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Section About Promo End -->

    <!-- Section Features -->
    <div class="section">
      <div class="widget-features-grid">
        <div class="wrapper-inner">
          <!-- Features Title -->
          <div class="widget-title">
            <h5>Let Us Host Your</h5>
            <h2>Events & Occasions</h2>
            <p>
              Our state-of-the-art facility in Annapolis is the perfect location to host your next event, whether it’s a
              wedding, a class reunion, a corporate event. We have a wide range of both indoor and outdoor event spaces
              which can be tailored to fit your unique event needs.
            </p>
          </div>
          <!-- Features Title End -->
          <!-- Features Content -->
          <div class="widget-inner">
            <div class="row">
              <div v-for="(item, index) in services" class="col-lg-4 col-sm-6">
                <div class="features-item" :style="'background-image:url(' + item.image + ');'">
                  <a :href="item.href">
                    <h3>{{ item.name }}</h3>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- Features Content End -->
        </div>
      </div>
    </div>
  </div>
  <!-- Site Main End -->
</template>

<script>
import services from "@/data/services.json";
import Rooms from "@/components/widgets/Rooms.vue";
export default {
  name: "Home",
  created() {
    document.title = "Fluegel Alumni Center";
  },
  mounted() {
    (function ($) {
      "use strict";

      /*-- VARIABLES --*/
      var window_width = $(window).innerWidth(),
        window_height = $(window).innerHeight(),
        site_backtop = $(".site-backtop"),
        site_loading = $(".site-loading");
      /*-- VARIABLES END--*/

      /*-- FUNCTIONS --*/
      function backTop() {
        if ($(window).scrollTop() > 40 && window_width > 767) {
          $(site_backtop).fadeIn();
        } else {
          $(site_backtop).fadeOut();
        }
      }
      function loadingStop() {
        $(site_loading).delay(100).fadeOut("slow");
      }
      /*-- FUNCTIONS END --*/

      /*-- BACK TOP --*/
      $(".site-backtop").on("click", function (e) {
        e.preventDefault();
        $("body, html").animate({ scrollTop: 0 }, 800);
      });
      /*-- BACK TOP END --*/

      /*-- HEADER MENU --*/
      $(".site-header .header-nav li.sub > a").on("click", function (e) {
        if (window_width < 1200) {
          e.preventDefault();
          var parent = $(this).parent("li"),
            target = $(this).siblings("ul");

          if (parent.hasClass("active")) {
            target.hide();
            parent.removeClass("active");
          } else {
            target.show();
            parent.addClass("active");
          }
        }
      });
      /*-- HEADER MENU END --*/

      /*-- HEADER TOGGLE MENU --*/
      $(".site-header .header-toggle").on("click", function (e) {
        e.preventDefault();
        var parent = $(".site-header"),
          target = $(".site-header .header-nav");

        if (target.is(":visible")) {
          parent.removeClass("nav-open");
        } else {
          parent.addClass("nav-open");
        }
      });
      /*-- HEADER TOGGLE MENU END --*/

      /*-- BACKGROUND IMAGES --*/
      $("[data-background]").each(function () {
        var src = $(this).data("background");
        if (src) {
          $(this).css("background-image", "url(" + src + ")");
        }
      });
      /*-- BACKGROUND IMAGES END --*/

      /*-- MAGNIFIC POPUP --*/
      if ($(".popup-photo").length) {
        $(".popup-photo").magnificPopup({
          type: "image",
        });
      }
      if ($(".popup-gallery").length) {
        $(".popup-gallery").magnificPopup({
          type: "image",
          gallery: {
            enabled: true,
          },
        });
      }
      if ($(".popup-video").length) {
        $(".popup-video").magnificPopup({
          type: "iframe",
        });
      }
      /*-- MAGNIFIC POPUP --*/

      /*-- DATEPICKER --*/
      if ($("input.datepicker").length) {
        var datepicker_width = $("input.datepicker").outerWidth();
        $("input.datepicker").datepicker();
        $("body").append(
          "<style>.ui-datepicker{width:auto; min-width: " +
          datepicker_width +
          "px !important;}</style>"
        );
      }
      /*-- DATEPICKER END --*/

      /*-- FITVIDS --*/
      if ($(".video-full").length) {
        $(".video-full").fitVids();
      }
      /*-- FITVIDS END --*/

      /*-- WIDGET SLIDER --*/
      if ($(".widget-slider").length) {
        $(".widget-slider .widget-carousel").owlCarousel({
          items: 1,
          nav: true,
          navText: ["", ""],
          dots: true,
          autoHeight: true,
          animateOut: "fadeOut",
          animateIn: "fadeIn",
          onInitialized: function () {
            $(".site-header").addClass("header-over");

            if ($(".widget-rooms-carousel.top-over").length) {
              $(".widget-slider").addClass("has-rooms");
            }
          },
        });
      }
      /*-- WIDGET SLIDER END --*/

      /*-- WIDGET GALLERY GRID --*/
      if ($(".widget-gallery-grid").length) {
        $(".widget-gallery-grid .gallery-item a").imagesLoaded(
          { background: true },
          function () {
            // Isotope
            var isotope_photos = $(".widget-gallery-grid .row");

            // Isotope Popups
            isotope_photos.on("arrangeComplete", function () {
              $(".widget-gallery-grid").magnificPopup({
                delegate: ".isotope-item:visible a",
                type: "image",
                gallery: {
                  enabled: true,
                },
              });
            });

            // Isotope Run
            isotope_photos.isotope({ itemSelector: ".isotope-item" });

            // Isotope Filter
            $(".widget-filter-top ul li a").on("click", function (e) {
              e.preventDefault();
              var filter_value = $(this).attr("data-filter");
              isotope_photos.isotope({ filter: filter_value });
              $(".widget-filter-top ul li").removeClass("active");
              $(this).parent("li").addClass("active");
            });
          }
        );
      }
      /*-- WIDGET GALLERY GRID END --*/

      /*-- WIDGET GALLERY CAROUSEL --*/
      if ($(".widget-gallery-carousel").length) {
        $(".widget-gallery-carousel .widget-carousel").owlCarousel({
          center: true,
          loop: true,
          nav: true,
          navText: ["", ""],
          dots: false,
          mouseDrag: false,
          responsive: {
            0: { items: 1 },
            768: { items: 3 },
          },
        });
      }
      /*-- WIDGET GALLERY CAROUSEL END --*/

      /*-- WIDGET ROOMS CAROUSEL --*/
      if ($(".widget-rooms-carousel").length) {
        $(".widget-rooms-carousel .widget-carousel").owlCarousel({
          responsive: {
            0: { items: 1 },
            991: { items: 2 },
            1200: { items: 3 },
          },
        });
      }
      /*-- WIDGET ROOMS CAROUSEL END --*/

      /*-- WIDGET ROOMS DETAIL --*/
      if ($(".widget-rooms-detail").length) {
        var sync1 = $(".widget-rooms-detail .room-slider .owl-carousel"),
          sync2 = $(".widget-rooms-detail .room-thumbnails .owl-carousel");

        sync1
          .owlCarousel({
            items: 1,
            nav: true,
            navText: ["", ""],
            dots: false,
            mouseDrag: false,
          })
          .on("changed.owl.carousel", function (e) {
            sync2.trigger("to.owl.carousel", [e.item.index, 250, true]);
          });

        sync2
          .owlCarousel({
            margin: 20,
            dots: false,
            responsive: {
              0: { items: 1 },
              768: { items: 2 },
              991: { items: 3 },
            },
          })
          .on("click", ".owl-item a", function (e) {
            e.preventDefault();
            sync1.trigger("to.owl.carousel", [
              $(this).parent().index(),
              250,
              true,
            ]);
          });
      }
      /*-- WIDGET ROOMS DETAIL END --*/

      /*-- WIDGET BLOG LIST --*/
      if ($(".widget-blog-list").length) {
        $(".widget-blog-list .media-gallery .media-carousel").owlCarousel({
          items: 1,
          navText: ["", ""],
        });
      }
      /*-- WIDGET BLOG LIST END --*/

      /*-- WIDGET BLOG CAROUSEL --*/
      if ($(".widget-blog-carousel").length) {
        $(".widget-blog-carousel .widget-carousel").owlCarousel({
          responsive: {
            0: { items: 1 },
            768: { items: 2 },
            992: { items: 3 },
            1200: { items: 4 },
          },
          onRefreshed: function () {
            var items = $(".widget-blog-carousel .widget-carousel .blog-item"),
              height = 0;

            items.removeAttr("style");
            items.each(function () {
              if ($(this).height() > height) {
                height = $(this).height();
              }
            });
            items.css("height", height);
          },
        });

        $(".widget-blog-carousel .media-gallery .media-carousel").owlCarousel({
          items: 1,
          mouseDrag: false,
          navText: ["", ""],
        });
      }
      /*-- WIDGET BLOG CAROUSEL END --*/

      /*-- WIDGET BLOG SINGLE --*/
      if ($(".widget-blog-single").length) {
        $(".widget-blog-single .media-gallery .media-carousel").owlCarousel({
          items: 1,
          nav: true,
          dots: false,
          navText: ["", ""],
          mouseDrag: false,
          autoplay: true,
        });
      }
      /*-- WIDGET BLOG SINGLE END --*/

      /*-- WIDGET TESTIMONIALS CAROUSEL --*/
      if ($(".widget-testimonials-carousel").length) {
        $(".widget-testimonials-carousel .widget-carousel").owlCarousel({
          margin: 40,
          responsive: {
            0: { items: 1 },
            768: { items: 2 },
            992: { items: 3 },
          },
        });
      }
      /*-- WIDGET TESTIMONIALS CAROUSEL END --*/

      /*-- WIDGET FEATURES CAROUSEL --*/
      if ($(".widget-features-carousel").length) {
        $(".widget-features-carousel .widget-carousel").owlCarousel({
          margin: 40,
          responsive: {
            0: { items: 1 },
            768: { items: 2 },
            992: { items: 3 },
            1200: { items: 5 },
          },
        });
      }
      /*-- WIDGET FEATURES CAROUSEL END --*/

      /*-- WIDGET TEAM CAROUSEL --*/
      if ($(".widget-team-carousel").length) {
        $(".widget-team-carousel .widget-carousel").owlCarousel({
          margin: 50,
          responsive: {
            0: { items: 1 },
            768: { items: 2 },
            992: { items: 3 },
            1200: { items: 4 },
          },
        });
      }
      /*-- WIDGET TEAM CAROUSEL END --*/

      /*-- WINDOW SCROLL --*/
      $(window).scroll(function () {
        backTop();
      });
      /*-- WINDOW SCROLL END --*/

      /*-- WINDOW LOAD --*/
      $(window).load(function () {
        loadingStop();
      });
      /*-- WINDOW LOAD END --*/

      /*-- WINDOW RESIZE --*/
      $(window).resize(function () {
        window_width = $(window).innerWidth();
        window_height = $(window).innerHeight();
      });
      /*-- WINDOW RESIZE END --*/
    })(jQuery);
  },
  components: {
    Rooms,
  },
  page: {
    title: "",
    meta: [{ name: "" }],
  },
 
  methods: {
  
  },
  data() {
    return {
      title: "",
      items: [
        {
          text: "",
          href: "/",
        },
        {
          text: "",
          active: true,
        },
      ],
      services,
    };
  },
};
</script>
