<template>
    <!-- Section Map -->
    <div class="section">
      <div class="wrapper-full">
        <div class="widget-google-map">
          <!-- Google Map Title -->
          <div class="map-title">
            <i class="fa fa-map-marker"></i>
            <h5>MAP</h5>
          </div>
          <!-- Google Map Title End -->
          <!-- Google Map -->
          <div class="map-google"></div>
          <!-- Google Map End -->
        </div>
      </div>
    </div>
    <!-- Section Map End -->
  </template>
  <script>
  export default {
    name: "Map",
    page: {
      title: "Map",
      meta: [
        {
          name: "description",
        },
      ],
    },
    components: { Map },
    mounted() {
      (function ($) {
        /*-- WIDGET GOOGLE MAP --*/
        if ($(".widget-google-map").length) {
          try {
            $(".widget-google-map .map-google")
              .width("100%")
              .height("100%")
              .gmap3({
                map: {
                  options: {
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    disableDefaultUI: false,
                    zoomControl: true,
                    center: [38.9860012, -76.4963927],
                    zoom: 15,
                    scrollwheel: false,
                    styles: [
                      { featureType: "all", elementType: "labels.text.fill" },
                    ],
                  },
                },
                marker: {
                  latLng: [38.9860012, -76.4963927],
                  options: { icon: "/assets/images/icons/map-marker.png" },
                },
              });
          } catch (error) {
            console.log(error);
          }
        }
        /*-- WIDGET GOOGLE MAP END --*/
      })(jQuery);
    },
    props: {},
  };
  </script>
  