<template>
  <!-- Section Page Title -->
  <div class="section">
    <div class="widget-page-title">
      <div class="widget-background" :style="{ backgroundImage: 'url(' + image + ')' }"></div>
      <div class="wrapper-inner">
        <!-- Title -->
        <h5>{{ titleInfo.subtitle }}</h5>
        <h1>{{ titleInfo.title }}</h1>
        <p>{{ titleInfo.description }}</p>
        <!-- Title End -->
        <!-- Breadcrumb -->
        <div class="widget-breadcrumb">
          <ul>
            <li><a href="#">HOME</a></li>
            <li><a href="#">PAGES</a></li>
            <li :style="{ textTransform: 'uppercase' }">{{ titleInfo.breadcrumb }}</li>
          </ul>
        </div>
        <!-- Breadcrumb End -->
      </div>
    </div>
  </div>
  <!-- Section Page Title End -->
</template>
<script>
export default {
  name: "PageTitle",

  methods: {  },
  props: {
    titleInfo: {
      type: Object,
      required: true,
    },
    image: {
      type: String,
      default: "assets/images/gallery/Exterior/PC%20Weekend%201.jpg",
    }
  },
};
</script>