

<template>
  <!-- Site Main -->
  <div class="site-main">
    <PageTitle :titleInfo="titleInfo"></PageTitle>

    <!-- Section Gallery -->
    <div class="section">
      <div class="wrapper-inner">
        <!-- Gallery Filter -->
        <div class="widget-filter-top">
          <ul>
            <li class="active"><a href="#" data-filter="*">ALL PHOTOS</a></li>
            <li><a href="#" data-filter=".Interior">Interior</a></li>
            <li><a href="#" data-filter=".Exterior">Exterior</a></li>
          </ul>
        </div>
        <!-- Gallery Filter End -->
        <!-- Gallery List -->
        <div class="widget-gallery-grid">
          <div class="row">
            <div
              v-for="(item, index) in gallery"
              class="col-lg-3 col-md-4 col-sm-6 col-xs-12 isotope-item"
              :class="item.subfolder"
            >
              <div class="gallery-item">
                <a
                  :href="`/assets/images/gallery/${item.subfolder}/${item.filename}.${item.extension}`"
                  :style="`background-image: url('/assets/images/gallery/${item.subfolder}/${item.filename}.${item.extension}')`"
                  :title="item.filename"
                  class="popup-gallery"
                ></a>
              </div>
            </div>
          </div>
        </div>
        <!-- Gallery List End -->
      </div>
    </div>
    <!-- Section Gallery End -->
  </div>
  <!-- Site Main End -->
</template>
<script>
const PageTitle = require("@/components/PageTitle.vue");
let gallery = require("@/data/gallery.json");
export default {
  name: "Gallery",
  components: {
    PageTitle,
  },
  inject: ["appInsights"],
  methods: {
    handleClick() {
      this.appInsights.trackEvent({ name: "buttonClicked" });
    },
  },
  data() {
    return {
      titleInfo: {
        subtitle: "Check out our",
        title: "Gallery",
        description:
          "Imagine the possibilities for your next event at the Fluegel Alumni Center.  Please note the facility will open for rentals in the Fall of 2023, at which time we will have event photos to share.  Please note the below images are design renderings and may not exactly depict the finished spaces.",
        breadcrumb: "Gallery",
      },
      gallery,
    };
  },
  page: {
    title: "Gallery",
    meta: [
      {
        name: "description",
      },
    ],
  },
  created() {
    document.title = "Gallery | Fluegel Alumni Center";
  },
  mounted() {
    (function ($) {
      "use strict";

      /*-- VARIABLES --*/
      let window_width = $(window).innerWidth(),
        window_height = $(window).innerHeight(),
        site_backtop = $(".site-backtop"),
        site_loading = $(".site-loading");
      /*-- VARIABLES END--*/

      /*-- FUNCTIONS --*/
      function backTop() {
        if ($(window).scrollTop() > 40 && window_width > 767) {
          $(site_backtop).fadeIn();
        } else {
          $(site_backtop).fadeOut();
        }
      }
      function loadingStop() {
        $(site_loading).delay(100).fadeOut("slow");
      }
      /*-- FUNCTIONS END --*/

      /*-- BACK TOP --*/
      $(".site-backtop").on("click", function (e) {
        e.preventDefault();
        $("body, html").animate({ scrollTop: 0 }, 800);
      });
      /*-- BACK TOP END --*/

      /*-- HEADER TOGGLE MENU --*/
      $(".site-header .header-toggle").on("click", function (e) {
        e.preventDefault();
        var parent = $(".site-header"),
          target = $(".site-header .header-nav");

        if (target.is(":visible")) {
          parent.removeClass("nav-open");
        } else {
          parent.addClass("nav-open");
        }
      });
      /*-- HEADER TOGGLE MENU END --*/

      /*-- BACKGROUND IMAGES --*/
      $("[data-background]").each(function () {
        var src = $(this).data("background");
        if (src) {
          $(this).css("background-image", "url(" + src + ")");
        }
      });
      /*-- BACKGROUND IMAGES END --*/

      /*-- MAGNIFIC POPUP --*/
      if ($(".popup-photo").length) {
        $(".popup-photo").magnificPopup({
          type: "image",
        });
      }
      if ($(".popup-gallery").length) {
        $(".popup-gallery").magnificPopup({
          type: "image",
          gallery: {
            enabled: true,
          },
        });
      }
      if ($(".popup-video").length) {
        $(".popup-video").magnificPopup({
          type: "iframe",
        });
      }
      /*-- MAGNIFIC POPUP --*/

      /*-- DATEPICKER --*/
      if ($("input.datepicker").length) {
        var datepicker_width = $("input.datepicker").outerWidth();
        $("input.datepicker").datepicker();
        $("body").append(
          "<style>.ui-datepicker{width:auto; min-width: " +
            datepicker_width +
            "px !important;}</style>"
        );
      }
      /*-- DATEPICKER END --*/

      /*-- FITVIDS --*/
      if ($(".video-full").length) {
        $(".video-full").fitVids();
      }
      /*-- FITVIDS END --*/

      /*-- WIDGET GALLERY GRID --*/
      if ($(".widget-gallery-grid").length) {
        $(".widget-gallery-grid .gallery-item a").imagesLoaded(
          { background: true },
          function () {
            // Isotope
            var isotope_photos = $(".widget-gallery-grid .row");

            // Isotope Popups
            isotope_photos.on("arrangeComplete", function () {
              $(".widget-gallery-grid").magnificPopup({
                delegate: ".isotope-item:visible a",
                type: "image",
                gallery: {
                  enabled: true,
                },
              });
            });

            // Isotope Run
            isotope_photos.isotope({ itemSelector: ".isotope-item" });

            // Isotope Filter
            $(".widget-filter-top ul li a").on("click", function (e) {
              e.preventDefault();
              var filter_value = $(this).attr("data-filter");
              isotope_photos.isotope({ filter: filter_value });
              $(".widget-filter-top ul li").removeClass("active");
              $(this).parent("li").addClass("active");
            });
          }
        );
      }
      /*-- WIDGET GALLERY GRID END --*/

      /*-- WINDOW SCROLL --*/
      $(window).scroll(function () {
        backTop();
      });
      /*-- WINDOW SCROLL END --*/

      /*-- WINDOW LOAD --*/
      $(window).load(function () {
        loadingStop();
      });
      /*-- WINDOW LOAD END --*/

      /*-- WINDOW RESIZE --*/
      $(window).resize(function () {
        window_width = $(window).innerWidth();
        window_height = $(window).innerHeight();
      });
      /*-- WINDOW RESIZE END --*/
    })(jQuery);
  },
  props: {},
};
</script>
