<style scoped>
#form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Adjust the height as needed */
  padding: 20px;
  box-sizing: border-box;
}

form {
  width: 100%; /* Or a fixed width, e.g., 500px */
  max-width: 500px; /* Maximum width of the form */
  background: #fff; /* Background color */
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  border-radius: 10px; /* Rounded corners */
}

input, textarea, select, button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  background-color: #0044cc; /* Adjust the button color */
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #003399;
}

  </style>
<template>
  <!-- Site Main -->
  <div class="site-main">
    <PageTitle :titleInfo="titleInfo"></PageTitle>

    <!-- Section Contact -->
    <div class="section">
      <div class="wrapper-inner">
        <div class="row">
          <div class="col-lg-12">
            <!-- Contact Info -->
            <div class="widget-contact-info">
              <div id="form-wrapper">
                
              </div>
              
              <ul style="display: none">
                <li style="text-align: center">
                  <h5>USNA Alumni Association & Foundation</h5>
                  <ul>
                    <li>
                      <a :href="globals.mapUrl"
                        ><i class="fa fa-map-marker"></i
                        >{{ globals.address }}</a
                      >
                    </li>
                    <li>
                      <a :href="globals.phoneNumberLink"
                        ><i class="fa fa-phone"></i>{{ globals.phoneNumber }}</a
                      >
                    </li>
                    <li>
                      <a :href="`mailto:${globals.email}`"
                        ><i class="fa fa-envelope"></i>{{ globals.email }}</a
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <!-- Contact Info End -->
          </div>
          <div class="col-lg-6" style="display: none">
            <!-- Contact Form -->
            <div class="widget-contact-form">
              <h5>CONTACT FORM</h5>
              <p>
                We are eager to hear from you; please fill in your contact
                information and one of our staff members will contact you
                shortly.
              </p>
              <div class="data-form">
                <form action="contact.php" id="contact-form" method="post">
                  <div class="row">
                    <div class="col-md-6">
                      <input
                        type="text"
                        id="form-name"
                        name="form-name"
                        placeholder="YOUR NAME"
                      />
                    </div>
                    <div class="col-md-6">
                      <input
                        type="text"
                        id="form-surname"
                        name="form-surname"
                        placeholder="YOUR SURNAME"
                      />
                    </div>
                    <div class="col-md-6">
                      <input
                        type="text"
                        id="form-email"
                        name="form-email"
                        placeholder="YOUR EMAIL ADDRESS"
                      />
                    </div>
                    <div class="col-md-6">
                      <input
                        type="text"
                        id="form-phone"
                        name="form-phone"
                        placeholder="YOUR PHONE"
                      />
                    </div>
                    <div class="col-md-12">
                      <textarea
                        cols="6"
                        rows="8"
                        id="form-message"
                        name="form-message"
                        placeholder="YOUR MESSAGE"
                      ></textarea>
                    </div>
                    <div class="col-md-6">
                      <p class="note">
                        * Please fill in all of the required fields
                      </p>
                    </div>
                    <div class="col-md-6 align-right">
                      <input type="submit" value="SEND FORM" class="btn" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!-- Contact Form End -->
          </div>
        </div>
      </div>
    </div>
    <!-- Section Contact End -->

    <Map></Map>
  </div>
  <!-- Site Main End -->
</template>
<script>
import PageTitle from "@/components/Contact.vue";
import Map from "@/components/widgets/Map.vue";
import globals from "@/data/globals.json";
export default {
  name: "Contact",
  components: {
    Map,
    PageTitle,
  },
 
  methods: {
  
    applyStyles() {
      let styles = document.createElement('style');
      styles.innerHTML = `
      #form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

form {
  width: 100%;
  max-width: 500px;
  background: #fff;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

@media (min-width: 768px) {
  form {
    max-width: 50%; /* Form takes up to 50% of the viewport width on larger screens */
  }
}

input, textarea, select, button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  background-color: #0044cc;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #003399;
}

      body #ui-datepicker-div {
top: auto !important;
left: auto !important;
}
      `;
      document.head.appendChild(styles);
    }
  },
  data() {
    return {
      titleInfo: {
        title: "Contact Us",
        subtitle: "Get In Touch",
        description:
          "We’d love to host your next meeting, event, or occasion.  Please fill out our contact form to get in touch, and a member of our staff will get back to you within 3 business days.",
        breadcrumb: "contact",
      },
      globals,
    };
  },
  page: {
    title: "Contact",
    meta: [
      {
        name: "description",
      },
    ],
  },
  created() {
    document.title = "Contact | Fluegel Alumni Center";
  },
  mounted() {
    let script = document.createElement('script');
    script.src = "https://api.tripleseat.com/v1/leads/ts_script.js?lead_form_id=30072&public_key=209338e9f9ed54eebfc05afc67bae14ee816c2ca&inline_form=true";
    script.onload = () => {
    // Apply styles after the script has loaded
    this.applyStyles();
  };
    document.getElementById('form-wrapper').appendChild(script);

    (function ($) {
      "use strict";

      /*-- VARIABLES --*/
      var window_width = $(window).innerWidth(),
        window_height = $(window).innerHeight(),
        site_backtop = $(".site-backtop"),
        site_loading = $(".site-loading");
      /*-- VARIABLES END--*/

      /*-- FUNCTIONS --*/
      function backTop() {
        if ($(window).scrollTop() > 40 && window_width > 767) {
          $(site_backtop).fadeIn();
        } else {
          $(site_backtop).fadeOut();
        }
      }
      function loadingStop() {
        $(site_loading).delay(100).fadeOut("slow");
      }
      /*-- FUNCTIONS END --*/

      /*-- BACK TOP --*/
      $(".site-backtop").on("click", function (e) {
        e.preventDefault();
        $("body, html").animate({ scrollTop: 0 }, 800);
      });
      /*-- BACK TOP END --*/

      /*-- HEADER MENU --*/
      $(".site-header .header-nav li.sub > a").on("click", function (e) {
        if (window_width < 1200) {
          e.preventDefault();
          var parent = $(this).parent("li"),
            target = $(this).siblings("ul");

          if (parent.hasClass("active")) {
            target.hide();
            parent.removeClass("active");
          } else {
            target.show();
            parent.addClass("active");
          }
        }
      });
      /*-- HEADER MENU END --*/

      /*-- HEADER TOGGLE MENU --*/
      $(".site-header .header-toggle").on("click", function (e) {
        e.preventDefault();
        var parent = $(".site-header"),
          target = $(".site-header .header-nav");

        if (target.is(":visible")) {
          parent.removeClass("nav-open");
        } else {
          parent.addClass("nav-open");
        }
      });
      /*-- HEADER TOGGLE MENU END --*/

      /*-- BACKGROUND IMAGES --*/
      $("[data-background]").each(function () {
        var src = $(this).data("background");
        if (src) {
          $(this).css("background-image", "url(" + src + ")");
        }
      });
      /*-- BACKGROUND IMAGES END --*/

      /*-- MAGNIFIC POPUP --*/
      if ($(".popup-photo").length) {
        $(".popup-photo").magnificPopup({
          type: "image",
        });
      }
      if ($(".popup-gallery").length) {
        $(".popup-gallery").magnificPopup({
          type: "image",
          gallery: {
            enabled: true,
          },
        });
      }
      if ($(".popup-video").length) {
        $(".popup-video").magnificPopup({
          type: "iframe",
        });
      }
      /*-- MAGNIFIC POPUP --*/

      /*-- DATEPICKER --*/
      if ($("input.datepicker").length) {
        var datepicker_width = $("input.datepicker").outerWidth();
        $("input.datepicker").datepicker();
        $("body").append(
          "<style>.ui-datepicker{width:auto; min-width: " +
            datepicker_width +
            "px !important;}</style>"
        );
      }
      /*-- DATEPICKER END --*/

      /*-- FITVIDS --*/
      if ($(".video-full").length) {
        $(".video-full").fitVids();
      }
      /*-- FITVIDS END --*/

      /*-- WIDGET SLIDER --*/
      if ($(".widget-slider").length) {
        $(".widget-slider .widget-carousel").owlCarousel({
          items: 1,
          nav: true,
          navText: ["", ""],
          dots: true,
          autoHeight: true,
          animateOut: "fadeOut",
          animateIn: "fadeIn",
          onInitialized: function () {
            $(".site-header").addClass("header-over");

            if ($(".widget-rooms-carousel.top-over").length) {
              $(".widget-slider").addClass("has-rooms");
            }
          },
        });
      }
      /*-- WIDGET SLIDER END --*/

      /*-- WIDGET GALLERY GRID --*/
      if ($(".widget-gallery-grid").length) {
        $(".widget-gallery-grid .gallery-item a").imagesLoaded(
          { background: true },
          function () {
            // Isotope
            var isotope_photos = $(".widget-gallery-grid .row");

            // Isotope Popups
            isotope_photos.on("arrangeComplete", function () {
              $(".widget-gallery-grid").magnificPopup({
                delegate: ".isotope-item:visible a",
                type: "image",
                gallery: {
                  enabled: true,
                },
              });
            });

            // Isotope Run
            isotope_photos.isotope({ itemSelector: ".isotope-item" });

            // Isotope Filter
            $(".widget-filter-top ul li a").on("click", function (e) {
              e.preventDefault();
              var filter_value = $(this).attr("data-filter");
              isotope_photos.isotope({ filter: filter_value });
              $(".widget-filter-top ul li").removeClass("active");
              $(this).parent("li").addClass("active");
            });
          }
        );
      }
      /*-- WIDGET GALLERY GRID END --*/

      /*-- WIDGET GALLERY CAROUSEL --*/
      if ($(".widget-gallery-carousel").length) {
        $(".widget-gallery-carousel .widget-carousel").owlCarousel({
          center: true,
          loop: true,
          nav: true,
          navText: ["", ""],
          dots: false,
          mouseDrag: false,
          responsive: {
            0: { items: 1 },
            768: { items: 3 },
          },
        });
      }
      /*-- WIDGET GALLERY CAROUSEL END --*/

      /*-- WIDGET ROOMS CAROUSEL --*/
      if ($(".widget-rooms-carousel").length) {
        $(".widget-rooms-carousel .widget-carousel").owlCarousel({
          responsive: {
            0: { items: 1 },
            991: { items: 2 },
            1200: { items: 3 },
          },
        });
      }
      /*-- WIDGET ROOMS CAROUSEL END --*/

      /*-- WIDGET ROOMS DETAIL --*/
      if ($(".widget-rooms-detail").length) {
        var sync1 = $(".widget-rooms-detail .room-slider .owl-carousel"),
          sync2 = $(".widget-rooms-detail .room-thumbnails .owl-carousel");

        sync1
          .owlCarousel({
            items: 1,
            nav: true,
            navText: ["", ""],
            dots: false,
            mouseDrag: false,
          })
          .on("changed.owl.carousel", function (e) {
            sync2.trigger("to.owl.carousel", [e.item.index, 250, true]);
          });

        sync2
          .owlCarousel({
            margin: 20,
            dots: false,
            responsive: {
              0: { items: 1 },
              768: { items: 2 },
              991: { items: 3 },
            },
          })
          .on("click", ".owl-item a", function (e) {
            e.preventDefault();
            sync1.trigger("to.owl.carousel", [
              $(this).parent().index(),
              250,
              true,
            ]);
          });
      }
      /*-- WIDGET ROOMS DETAIL END --*/

      /*-- WIDGET BLOG LIST --*/
      if ($(".widget-blog-list").length) {
        $(".widget-blog-list .media-gallery .media-carousel").owlCarousel({
          items: 1,
          navText: ["", ""],
        });
      }
      /*-- WIDGET BLOG LIST END --*/

      /*-- WIDGET BLOG CAROUSEL --*/
      if ($(".widget-blog-carousel").length) {
        $(".widget-blog-carousel .widget-carousel").owlCarousel({
          responsive: {
            0: { items: 1 },
            768: { items: 2 },
            992: { items: 3 },
            1200: { items: 4 },
          },
          onRefreshed: function () {
            var items = $(".widget-blog-carousel .widget-carousel .blog-item"),
              height = 0;

            items.removeAttr("style");
            items.each(function () {
              if ($(this).height() > height) {
                height = $(this).height();
              }
            });
            items.css("height", height);
          },
        });

        $(".widget-blog-carousel .media-gallery .media-carousel").owlCarousel({
          items: 1,
          mouseDrag: false,
          navText: ["", ""],
        });
      }
      /*-- WIDGET BLOG CAROUSEL END --*/

      /*-- WIDGET BLOG SINGLE --*/
      if ($(".widget-blog-single").length) {
        $(".widget-blog-single .media-gallery .media-carousel").owlCarousel({
          items: 1,
          nav: true,
          dots: false,
          navText: ["", ""],
          mouseDrag: false,
          autoplay: true,
        });
      }
      /*-- WIDGET BLOG SINGLE END --*/

      /*-- WIDGET TESTIMONIALS CAROUSEL --*/
      if ($(".widget-testimonials-carousel").length) {
        $(".widget-testimonials-carousel .widget-carousel").owlCarousel({
          margin: 40,
          responsive: {
            0: { items: 1 },
            768: { items: 2 },
            992: { items: 3 },
          },
        });
      }
      /*-- WIDGET TESTIMONIALS CAROUSEL END --*/

      /*-- WIDGET FEATURES CAROUSEL --*/
      if ($(".widget-features-carousel").length) {
        $(".widget-features-carousel .widget-carousel").owlCarousel({
          margin: 40,
          responsive: {
            0: { items: 1 },
            768: { items: 2 },
            992: { items: 3 },
            1200: { items: 5 },
          },
        });
      }
      /*-- WIDGET FEATURES CAROUSEL END --*/

      /*-- WIDGET TEAM CAROUSEL --*/
      if ($(".widget-team-carousel").length) {
        $(".widget-team-carousel .widget-carousel").owlCarousel({
          margin: 50,
          responsive: {
            0: { items: 1 },
            768: { items: 2 },
            992: { items: 3 },
            1200: { items: 4 },
          },
        });
      }
      /*-- WIDGET TEAM CAROUSEL END --*/

      /*-- WINDOW SCROLL --*/
      $(window).scroll(function () {
        backTop();
      });
      /*-- WINDOW SCROLL END --*/

      /*-- WINDOW LOAD --*/
      $(window).load(function () {
        loadingStop();
      });
      /*-- WINDOW LOAD END --*/

      /*-- WINDOW RESIZE --*/
      $(window).resize(function () {
        window_width = $(window).innerWidth();
        window_height = $(window).innerHeight();
      });
      /*-- WINDOW RESIZE END --*/

      
    })(jQuery);
  },
  props: {},
};
</script>
