<template>
  <div class="site-footer">
    <!-- Footer Top -->
    <div class="footer-top">
      <div class="wrapper">
        <div class="row">
          <div class="col-lg-6">
            <h5>Follow Us</h5>
            <h6>ON SOCIAL MEDIA</h6>
            <div class="widget-social-icons">
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=100090429955449&mibextid=LQQJ4d"
                    target="_blank"
                    ><i class="fa fa-facebook"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="https://Instagram.com/FluegelAlumniCenter"
                    target="_blank"
                    ><i class="fa fa-instagram"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6">
            <a href="https://usna.com">
              <img
                :src="globals.usnaCrest"
                style="height: 130px"
                alt="USNA Alumni Association & Foundation Logo"
              />
            </a>
            <div style="display: none">
              <h5>NEWSLETTER</h5>
              <h6>WE'D LOVE TO STAY IN TOUCH WITH YOU</h6>
              <div class="widget-newsletter">
                <form>
                  <input
                    type="text"
                    placeholder="ENTER YOUR E-MAIL ADDRESS"
                    required
                  />
                  <button type="submit">
                    <i class="fa fa-paper-plane"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer Top End -->
    <!-- Footer Bottom -->
    <div class="footer-bottom">
      <div class="wrapper">
        <div class="footer-logo">
          <a href="/">
            <img :src="globals.logo" alt="Fluegel Alumni Center Logo" />
          </a>
        </div>
        <div class="footer-copyright">
          <p>
            COPYRIGHT ©
            <a href="https://usna.com"
              >U.S. NAVAL ACADEMY ALUMNI ASSOCIATION & FOUNDATION</a
            >
          </p>
        </div>
        <div class="footer-contact">
          <ul>
            <li>
              <a :href="globals.mapUrl" style="text-transform: uppercase"
                ><i class="fa fa-map-marker"></i>{{ globals.address }}</a
              >
            </li>
            <li>
              <a :href="globals.phoneNumberLink"
                ><i class="fa fa-phone"></i> {{ globals.phoneNumber }}</a
              >
            </li>
            <li>
              <a :href="globals.emailLink" style="text-transform: uppercase"
                ><i class="fa fa-envelope"></i> {{ globals.email }}</a
              >
            </li>
          </ul>
        </div>
        <div class="footer-nav">
          <ul>
            <li><a href="/privacy">PRIVACY POLICY</a></li>
            <li><a href="/terms">TERMS OF USE</a></li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Footer Bottom End -->
  </div>
</template>

<script>
import globals from "@/data/globals.json";
export default {
  name: "Footer",
  inject: ["appInsights"],
  methods: { handleClick() { this.appInsights.trackEvent({ name: 'buttonClicked' }) } },
  data() {
    return {
      globals,
    };
  },
  props: {},
};
</script>
