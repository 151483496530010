<template>
  <Header></Header>
  <router-view />
  <Footer></Footer>
</template>
<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      title: "Fluegel Alumni Center",
      facebookUrl: "https://www.facebook.com/profile.php?id=100090429955449&mibextid=LQQJ4d",
    };
  },
};
</script>
<style>
/* CSS */
@import "@/assets/css/bootstrap.min.css";
@import "@/assets/css/font.awesome.min.css";
@import "@/assets/css/magnific.popup.css";
@import "@/assets/css/owl.carousel.css";
@import "@/assets/css/owl.theme.default.css";
@import "@/assets/css/jquery-ui.css";
@import "@/assets/css/style.css";
@import "@/assets/css/responsive.css";

/* Google Fonts */
@import "https://fonts.googleapis.com/css?family=Playfair+Display:400,400italic,700,700italic,900,900italic&amp;subset=latin,latin-ext";
@import "https://fonts.googleapis.com/css?family=Lato:300,400,700,900&amp;subset=latin,latin-ext";
</style>
