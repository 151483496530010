<template>
  <!-- Site Main -->
  <div class="site-main">
    <PageTitle :titleInfo="titleInfo" />

    <div class="section">
      <div class="widget-about-promo">
        <div class="wrapper-inner">
          <div class="widget-inner">
            <div class="row">
              <div class="col-md-12">
                <h5>Ownership and Use of Content</h5>
                <p>
                  All content on this website, including but not limited to
                  text, graphics, logos, images, videos, and software, is the
                  property of Fluegel Alumni Center or its content suppliers and
                  is protected by U.S. and international copyright laws. You may
                  use the content only for the purpose of browsing our website
                  and obtaining information about our event space. Any other
                  use, including reproduction, modification, distribution,
                  transmission, or display of the content, is strictly
                  prohibited without our prior written consent.
                </p>
                <h5>User Conduct</h5>
                <p>
                  You agree not to use our website in any way that could damage,
                  disable, overburden, or impair our servers or networks, or
                  interfere with any other user's use and enjoyment of our
                  website. You also agree not to use our website to engage in
                  any fraudulent or illegal activities or to transmit any
                  viruses, worms, or other harmful code.
                </p>
                <h5>Third-Party Links</h5>
                <p>
                  Our website may contain links to third-party websites that are
                  not under our control. We are not responsible for the content
                  of any linked website or any link contained in a linked
                  website. We provide these links as a convenience only, and the
                  inclusion of any link does not imply our endorsement of the
                  linked website.
                </p>
                <h5>Limitation of Liability</h5>
                <p>
                  Fluegel Alumni Center shall not be liable for any direct,
                  indirect, incidental, special, or consequential damages
                  arising out of or in connection with your use of our website
                  or the information contained on it, even if we have been
                  advised of the possibility of such damages. In no event shall
                  our liability exceed the total amount paid by you, if any, for
                  accessing our website.
                </p>
                <h5>Indemnification</h5>
                <p>
                  You agree to indemnify and hold harmless Fluegel Alumni Center
                  and its officers, directors, employees, and agents from and
                  against any and all claims, damages, liabilities, costs, and
                  expenses (including reasonable attorneys' fees) arising out of
                  or in connection with your use of our website or your
                  violation of these terms of use.
                </p>
                <h5>Governing Law and Jurisdiction</h5>
                <p>
                  These terms of use shall be governed by and construed in
                  accordance with the laws of the State of Maryland, without
                  giving effect to any principles of conflicts of law. You agree
                  that any dispute arising out of or in connection with these
                  terms of use or your use of our website shall be resolved
                  exclusively in the state or federal courts located in Anne
                  Arundel County, Maryland.
                </p>
                <h5>Changes to Terms of Use</h5>
                <p>
                  We reserve the right to modify or update these terms of use at
                  any time without prior notice. Your continued use of our
                  website after any such changes constitutes your acceptance of
                  the new terms of use. Please check this page periodically for
                  updates.
                </p>
                <p>
                  If you have any questions or concerns about these terms of use
                  or our website, please contact us via the contact page on our
                  website.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Site Main End -->
</template>
<script>
import PageTitle from "@/components/PageTitle.vue";
export default {
  name: "Contact",
  components: {
    PageTitle,
  },
  inject: ["appInsights"],
  methods: {
    handleClick() {
      this.appInsights.trackEvent({ name: "buttonClicked" });
    },
  },
  data() {
    return {
      titleInfo: {
        title: "Terms Of Use",
        subtitle: "Fluegel Alumni Center",
        description:
          "By using our website, you agree to the following terms of use:",
        breadcrumb: "Terms Of Use",
      },
    };
  },
  page: {
    title: "Contact",
    meta: [
      {
        name: "description",
      },
    ],
  },
  created() {
    document.title = "Terms of Services | Fluegel Alumni Center";
  },
  mounted() {
    (function ($) {
      "use strict";

      /*-- VARIABLES --*/
      var window_width = $(window).innerWidth(),
        window_height = $(window).innerHeight(),
        site_backtop = $(".site-backtop"),
        site_loading = $(".site-loading");
      /*-- VARIABLES END--*/

      /*-- FUNCTIONS --*/
      function backTop() {
        if ($(window).scrollTop() > 40 && window_width > 767) {
          $(site_backtop).fadeIn();
        } else {
          $(site_backtop).fadeOut();
        }
      }
      function loadingStop() {
        $(site_loading).delay(100).fadeOut("slow");
      }
      /*-- FUNCTIONS END --*/

      /*-- BACK TOP --*/
      $(".site-backtop").on("click", function (e) {
        e.preventDefault();
        $("body, html").animate({ scrollTop: 0 }, 800);
      });
      /*-- BACK TOP END --*/

      /*-- HEADER MENU --*/
      $(".site-header .header-nav li.sub > a").on("click", function (e) {
        if (window_width < 1200) {
          e.preventDefault();
          var parent = $(this).parent("li"),
            target = $(this).siblings("ul");

          if (parent.hasClass("active")) {
            target.hide();
            parent.removeClass("active");
          } else {
            target.show();
            parent.addClass("active");
          }
        }
      });
      /*-- HEADER MENU END --*/

      /*-- HEADER TOGGLE MENU --*/
      $(".site-header .header-toggle").on("click", function (e) {
        e.preventDefault();
        var parent = $(".site-header"),
          target = $(".site-header .header-nav");

        if (target.is(":visible")) {
          parent.removeClass("nav-open");
        } else {
          parent.addClass("nav-open");
        }
      });
      /*-- HEADER TOGGLE MENU END --*/

      /*-- BACKGROUND IMAGES --*/
      $("[data-background]").each(function () {
        var src = $(this).data("background");
        if (src) {
          $(this).css("background-image", "url(" + src + ")");
        }
      });
      /*-- BACKGROUND IMAGES END --*/

      /*-- WINDOW SCROLL --*/
      $(window).scroll(function () {
        backTop();
      });
      /*-- WINDOW SCROLL END --*/

      /*-- WINDOW LOAD --*/
      $(window).load(function () {
        loadingStop();
      });
      /*-- WINDOW LOAD END --*/

      /*-- WINDOW RESIZE --*/
      $(window).resize(function () {
        window_width = $(window).innerWidth();
        window_height = $(window).innerHeight();
      });
      /*-- WINDOW RESIZE END --*/
    })(jQuery);
  },
  props: {},
};
</script>
